%list, .list {
	li:not(:last-child) { margin-bottom: 0.25em; }
}

%list_unstyled, .list_unstyled {
	@extend %list;
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

.list_inline {
	@extend %list_unstyled;
	li { display: inline-block; margin: 0 0.5rem 0.5rem 0 !important; }
}

.list_buttons {
	a {
		@extend %btn_link;
		@extend %btn--sm;
		border-radius: 3px;
	}
}

.list_spaced {
	li:not(:last-child) { margin-bottom: 0.75em; }
}

.list_icon {

	@extend %list;
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;

	li {
		padding-left: 2em;
		font-size: 1.125rem;
		background-repeat: no-repeat;
		background-size: 1.25rem;
		background-position: left center;
	}

}

	.icon_check-primary li { background-image: url("/_/images/icons/cm_primary.svg") }
	.icon_check-secondary li { background-image: url("/_/images/icons/cm_secondary.svg") }
	.icon_check-success li { background-image: url("/_/images/icons/cm_success.svg") }


.list_free {

	margin-top: 1em;

	li {
		position: relative;
		display: block;
		padding: 1em;
		font-size: 1rem;
		font-style: italic;
		line-height: 1.25em;
		&:nth-child(even) { background-color: rgba(0, 0, 0, 0.05); }
	}

		strong {
			display: block;
			font-size: 1.2rem;
			font-style: normal;
			line-height: 1.25em;
		}

		em {
			font-size: 1.2rem;
			font-weight: 600;
			font-style: normal;
			line-height: 1.25em;
			position: absolute;
			top: 1.35em;
			right: 1em;
		}

}