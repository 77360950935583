//.title_branding {}

	.branding_logo {

		display: block;
		width: 100%;
		max-width: 210px;
		margin: 0 auto;
 		@include transition(all ease-in-out 300ms);

		#logo {

			.h2o_slab { fill: $black; opacity: 0.325; @include transition(all ease-in-out 300ms) }
			.txt_slab { fill: lighten($color-secondary, 7.5%); @include transition(all ease-in-out 300ms) }

			.h2o_leak { fill: $black; opacity: 0.35; @include transition(all ease-in-out 300ms) }
			.txt_leak { fill: lighten($color-primary, 7.5%); @include transition(all ease-in-out 300ms) }

			.txt_repair { fill: #999; }

		}

		&:hover {
			transform: scale(1.025);
			@include transition(all ease-in-out 300ms);
			#logo {

				.h2o_leak,
				.h2o_slab { opacity: 0; @include transition(all ease-in-out 300ms) }
				.txt_slab { fill: $color-secondary; @include transition(all ease-in-out 300ms) }
				.txt_leak { fill: $color-primary; @include transition(all ease-in-out 300ms) }

			}
		}
		
		@include media-breakpoint-up(lg) { margin: 0 }

		.page_front & { max-width: 320px }

	}