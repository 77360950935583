$banner-bg: 			$white !default;
$banner-color: 			$color-text;

.banner {
	padding: 1em 0;
	border-bottom: 5px solid #ddd;
	box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
	background-color: $banner-bg;
	color: $banner-color;

	@include media-breakpoint-up(lg) {
		padding: 0.6em 0 0.2em;
	}

	.page_front & {
		padding: 2em 0 1.6em;
	}

}