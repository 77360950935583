@at-root {
    @-ms-viewport { width: device-width }
}

html {
	position: relative;
	height: 100%;
}

body {
	margin: 0;	
	background-color: $body-bg;
	color: $color-text;

	&.fix_foot {
		position: relative;
		height: 100%;
		min-height: 100%;
		margin-bottom: 90px;
		.site_info {
			position: absolute;
			bottom: -90px; left: 0; right: 0;
			width: 100%;
		}
	}

}

abbr[title] {
	border-bottom: none;
  	text-decoration: underline;
  	text-decoration: underline dotted;
}

sub,
sup {
  	font-size: 75%;
  	line-height: 0;
  	position: relative;
  	vertical-align: baseline;
}

	sub { bottom: -0.25em; }

	sup { top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
  	font-size: 100%;
  	line-height: 1.15;
  	margin: 0;
}

	button,
	input { overflow: visible; }

	button,
	select { text-transform: none; }

	button,
	[type="button"],
	[type="reset"],
	[type="submit"] { -webkit-appearance: button; }

	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }

	button:-moz-focusring,
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

	fieldset { padding: 0.35em 0.75em 0.625em; }

	legend {
		box-sizing: border-box;
		color: inherit;
		display: table;
		max-width: 100%;
		padding: 0;
		white-space: normal;
	}

	progress { vertical-align: baseline; }

	textarea { overflow: auto; }

	[type="checkbox"],
	[type="radio"] { box-sizing: border-box; padding: 0; }

	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button { height: auto; }

	[type="search"] {
		-webkit-appearance: textfield;
		outline-offset: -2px;
	}

	[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

	::-webkit-file-upload-button {
		-webkit-appearance: button;
	  	font: inherit;
	}

details { display: block; }
summary { display: list-item; }
template { display: none; }
[hidden] { display: none; }
