.copyright_data {

	ul > li { line-height: 1.1em; }

	a {
		color: $white;
		font-weight: 600;
		text-decoration: none;
	}

	@include media-breakpoint-up(md) {
		ul {
			margin-bottom: 0;
			> li { 
				display: inline-block;
				margin-bottom: 0 !important;
				&:not(:last-child) { margin-right: 1.5em; }
				&:first-child {
					width: 180px;
					#logo { vertical-align: middle; }
				}
			}
		}
	}


	@include media-breakpoint-up(md) {
		ul > li:not(:last-child) { margin-right: 3em; }
	}

}
