$site_info_bg: 			$color-primary;
$site_info_color: 		$white;


.site_info {

	border-top: 5px solid $white;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.5);
	background-color: $site_info_bg;
	color: $site_info_color;
	font-size: 0.9em;

	.site_info_logo {
		display: block;
		max-width: 180px;
		margin: 0 auto;
		#logo { fill: $white }
	}

	@include media-breakpoint-up(md) {
		padding: 2em 0 1.8em;
	}

}
