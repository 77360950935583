.nav_list {

	.nav_menu {
		> .menu_item {
			margin-bottom: 0.5em;
			> .menu_link {
				padding: 0.75em;
			}
		}

	}

}

.city_menu {

	background-color: $white;

	.nav_menu > .menu_item {

		float: left;
		width: 49%;
		&:nth-child(odd) { clear: both; }
		&:nth-child(even) { float: right; }

		> .menu_link {
			@include txt--sm;
		}

		> [href="#"] {
			border-color: #aaa;
			background-color: #ddd;
			color: $color-text; 
			&:hover {
				background-color: #ddd;
				cursor: default;
			}
		}

	}

	@include media-breakpoint-up(md) {
		.nav_menu { @include txt--center }
		.nav_menu > .menu_item {
			float: none;
			display: inline-block;
			width: auto;
			margin-right: 1%;
			&:nth-child(odd) { clear: none; }
			&:nth-child(even) { float: none; }
		}
	}


}